import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { format } from 'date-fns-tz';

import Api from "../../services/api";
import history from '../../history';

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";
import Header from "components/Header.js";
import Footer from "components/Footer.js";
import FormatNumber from 'components/FormatNumber';

function Pack() {
  const [packOrder, setPackOrder] = useState([]);
  const [pack, setPack] = useState();
  const { id } = useParams();
 

  useEffect(() => {
    (async () => {
      const response = await Api.get('/packorder/'+id).then(function(res){
        setPackOrder(res.data);
        // console.log(res.data)
        
      
      }).catch(function(error) {
        history.push('/expire/expire');
        // console.log(error);
        // console.log(error.response)
      });

    })();
    (async () => {
    const response2 = await Api.get('/pack/'+id).then(function(res){
        setPack(res.data);
        console.log(res.data)
        
      
      }).catch(function(error) {
        // history.push('/expire/expire');
        console.log(error);
        console.log(error.response2)
      });

    })();
  }, []);

  return (
    packOrder === undefined || pack === undefined  ? "Carregando" :
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <Navbar />
        {/* Header */}
        <Header />

        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 bpack-0">
                  <div className="flex flex-wrap items-center">
                    <div className="w-full px-4 max-w-full flex-grow flex-1">
                        <div className="w-full lg:w-8/12 px-4 float-left h-10">
                            <h1 className="font-semibold text-2xl text-gray-800">
                              Package Details
                            </h1><br />
                        </div>
                        <div className="w-full lg:w-4/12 px-4 float-left h-10">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                                <Link to="/packages">
                                    <button
                                    className="bg-gray-800 text-white hover:bg-yellow-700 text-xs font-bold uppercase px-4 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{ transition: "all .15s ease" }}
                                    >
                                      Back to Packages
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="w-full px-4">
                            <p>ID: {pack.id_pack}</p>
                            <p>Package created in: {format(new Date(pack.datepack), 'dd-MM-yyyy')} às {format(new Date(pack.datepack), 'HH:mm')} hs</p>
                            <p>Package Price: {pack.pricepack}</p><br />
                            <h2 className="font-semibold text-xl text-gray-800">
                              List of Orders inserted in this Package
                            </h2>
                        </div>
                        </div>

                  </div>
                </div>

                <div className="block w-full overflow-x-auto">
                  {/* Projects table */}
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Number
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Date
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Price
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                    packOrder?.map((pack) => {
                        return (
                          <tr>
                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                              <Link to={"/detail-order/" + pack.id}>{pack.id}</Link>
                            </th>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                              <Link to={"/detail-order/" + pack.id}>{format(new Date(pack.date), 'dd-MM-yyyy')} às {format(new Date(pack.date), 'HH:mm')} hs</Link>
                            </td>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                              <Link to={"/detail-order/" + pack.id}>{pack.price === 0 ? "Em Análise" : FormatNumber(pack.price)}</Link>
                            </td>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                              <Link to={"/detail-order/" + pack.id}>
                              <button className="bg-green-400 rounded px-2 py-1 text-white" > 
                                  <i class="fas fa-plus-circle text-white mr-2"></i>
                                  View
                                </button>
                              </Link>
                            </td>
                          </tr>    
                          )
                        })    
                      }
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
        </div>

        <Footer />
        </div>
      </div>
    </>
  );
}
export default Pack;