import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { format } from 'date-fns-tz';

import Api from "../../services/api";
import history from '../../history';

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";
import Header from "components/Header.js";
import Footer from "components/Footer.js";
import FormatNumber from 'components/FormatNumber';

function Dashboard() {
  const [orders, setOrders] = useState([]);
  const [packs, setPacks] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await Api.get('/orderAdmin').then(function(res){
        setOrders(res.data);
        console.log(orders)
      
      }).catch(function(error) {
        history.push('/expire/expire');
        console.log(error);
        console.log(error.response)
      });

    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await Api.get('/pack').then(function(res){
        setPacks(res.data);
        // console.log(packs);
        // console.log(res.data)
      
      }).catch(function(error) {
        history.push('/expire/expire');
        // console.log(error);
        // console.log(error.response)
      });

    })();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <Navbar />
        {/* Header */}
        <Header />

        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h3 className="font-semibold text-base text-gray-800">
                        Latest Orders
                      </h3>
                    </div>
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                      <Link to="/orders">
                        <button
                          className="bg-gray-800 text-white hover:bg-yellow-700 text-xs font-bold uppercase px-4 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                          style={{ transition: "all .15s ease" }}
                        >
                          See all
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto">
                  {/* Projects table */}
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Number
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Date
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Price
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          State
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                    orders?.slice(-4).map((order) => {
                      return (
                        <tr>
                          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-order/" + order.id}>{order.id}</Link>
                          </th>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-order/" + order.id}>{format(new Date(order.date), 'dd-MM-yyyy')} às {format(new Date(order.date), 'HH:mm')} hs</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-order/" + order.id}>{order.price === 0 ? "Em Análise" : FormatNumber(order.price)}</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-order/" + order.id}>{order.stateorder}</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-order/" + order.id}>
                            <button className="bg-green-400 rounded px-2 py-1 text-white">
                                <i class="fas fa-plus-circle text-white mr-2"></i>
                                View
                              </button>
                            </Link>
                          </td>
                        </tr>    
                        )
                      })    
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h3 className="font-semibold text-base text-gray-800">
                        Latest Packages
                      </h3>
                    </div>
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                      <Link to="/packages">
                        <button
                          className="bg-gray-800 text-white hover:bg-yellow-700 text-xs font-bold uppercase px-4 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                          style={{ transition: "all .15s ease" }}
                        >
                          See all
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto">
                  {/* Projects table */}
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Number
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Date
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Price
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          State
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Country
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                    packs?.map((pack) => {
                      return (
                        <tr>
                          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-package/" + pack.id}>{pack.id}</Link>
                          </th>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-package/" + pack.id}>{format(new Date(pack.datepack), 'dd-MM-yyyy')} às {format(new Date(pack.datepack), 'HH:mm')} hs</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-package/" + pack.id}>{pack.pricepack === 0 ? "Em Análise" : FormatNumber(pack.pricepack)}</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-package/" + pack.id}>{pack.statepack}</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-package/" + pack.id}>{pack.shipping}</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detail-package/" + pack.id}>
                            <button className="bg-green-400 rounded px-2 py-1 text-white">
                                <i class="fas fa-plus-circle text-white mr-2"></i>
                                View
                              </button>
                            </Link>
                          </td>
                        </tr>    
                        )
                      })    
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
}
export default Dashboard;
