import React from "react";

import logo from '../assets/img/logo-painel.png';

export default function Navbar() {
  return (
    <>
       {/* Navbar */}
       <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-no-wrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <h1 className="text-4xl">
            <a
              className="text-white hidden lg:inline-block font-semibold"
              href="#painel"
              onClick={(e) => e.preventDefault()}
            >
              Welcome to Buy in China Administrator
            </a>
          </h1>
          <div className="pr-2 hidden md:flex">
          <img
            alt="Compra na China"
            src={logo}
            className="h-auto align-middle border-none pr-2"
          />
        </div>
        </div>
       
      </nav>
      {/* End Navbar */}
    </>
  );
}
