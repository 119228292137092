import React, { useContext } from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';

import { Context } from './Context/AuthContext';

import Login from './pages/Login';
import Login2 from './pages/Login';
import Create from './pages/Create';
import Dashboard from './pages/Dashboard';
import Logout from './pages/Logout';
import ListUser from './pages/ListUser';
import EditUser from './pages/EditUser';
import Landing from './pages/landing';
import Profile from './pages/profile';
import Order from './pages/Order';
import newOrder from './pages/Order/new';
import viewOrder from './pages/Order/view';
import Pack from './pages/Pack';
import newPack from './pages/Pack/new';
import viewPack from './pages/Pack/view';

function CustomRoute({ isPrivate, ...rest }) {
  const { loading, authenticated } = useContext(Context);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/" />
  }

  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute exact path="/" component={Login} />
      <CustomRoute path="/expire/:acess" component={Login2} />
      <CustomRoute path="/cadastrar" component={Create} />
      <CustomRoute isPrivate path="/minhaconta" component={ListUser} />
      <CustomRoute isPrivate path="/editarconta" component={EditUser} />
      <CustomRoute isPrivate path="/panel" component={Dashboard} />
      <CustomRoute isPrivate path="/orders" component={Order} />
      <CustomRoute isPrivate path="/novo-pedido" component={newOrder} />
      <CustomRoute isPrivate path="/detail-order/:id" component={viewOrder} />
      <CustomRoute isPrivate path="/packages" component={Pack} />
      <CustomRoute isPrivate path="/novo-pacote" component={newPack} />
      <CustomRoute isPrivate path="/detail-package/:id" component={viewPack} />
      <CustomRoute path="/logout" component={Logout} />
      <CustomRoute path="/landing" component={Landing} />
      <CustomRoute path="/profile" component={Profile} />
    </Switch>
  );
}